import { DiscountCode } from '../../types/discount';
import { Product } from '../../types/product';
import { Range } from '../../types/range';
import { Option } from '../../types/option';
import { PaymentMethod } from '../../types/payment-method';
import { ExtendedGoogleMapsPlace } from '../address/types';

export const ADD_PRODUCT = 'ADD_PRODUCT';
export const SET_PRODUCTS = 'SET_PRODUCTS_CART';
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';
export const SET_DISCOUNT_CODE = 'SET_DISCOUNT_CODE';
export const REMOVE_DISCOUNT_CODE = 'REMOVE_DISCOUNT_CODE';
export const SET_PICKUP_RANGE = 'SET_PICKUP_RANGE';
export const SET_DROP_OFF_RANGE = 'SET_DROP_OFF_RANGE';
export const SET_OPTIONS = 'SET_OPTIONS';
export const SET_CURRENT_OPTION = 'SET_CURRENT_OPTION';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';
export const SET_ADDRESS = 'SET_ADDRESS';
export const RESET_CART = 'RESET_CART';
export const RESET_TIMESLOT = 'RESET_TIMESLOT';
export const SET_ADDRESS_MANUALLY_ADDED = 'SET_ADDRESS_MANUALLY_ADDED';

export interface ProductInCart {
  product: Product;
  number: number;
}

interface AddProduct {
  type: typeof ADD_PRODUCT;
  product: Product;
}

interface SetProducts {
  type: typeof SET_PRODUCTS;
  products: Product[];
}

interface RemoveProduct {
  type: typeof REMOVE_PRODUCT;
  product: Product;
}

interface SetDiscountCode {
  type: typeof SET_DISCOUNT_CODE;
  discountCode: DiscountCode;
}

interface RemoveDiscountCode {
  type: typeof REMOVE_DISCOUNT_CODE;
}

interface SetPickupRange {
  type: typeof SET_PICKUP_RANGE;
  pickupRange: Range;
}

interface SetDropOffRange {
  type: typeof SET_DROP_OFF_RANGE;
  dropOffRange: Range;
}

interface SetOptions {
  type: typeof SET_OPTIONS;
  options: Option[];
}

interface SetCurrentOption {
  type: typeof SET_CURRENT_OPTION;
  currentOption: Option;
}

interface SetPaymentMethod {
  type: typeof SET_PAYMENT_METHOD;
  paymentMethod: PaymentMethod;
}

interface SetAddress {
  type: typeof SET_ADDRESS;
  address: ExtendedGoogleMapsPlace | undefined;
}

interface ResetCart {
  type: typeof RESET_CART;
}

interface ResetTimeslot {
  type: typeof RESET_TIMESLOT;
}

interface SetAddressManuallyAdded {
  type: typeof SET_ADDRESS_MANUALLY_ADDED;
  value: boolean;
}

export type SetAddressActionTypes = SetAddress;

export type ShoppingCartActionTypes =
  | AddProduct
  | SetProducts
  | RemoveProduct
  | SetDiscountCode
  | RemoveDiscountCode
  | SetPickupRange
  | SetDropOffRange
  | SetOptions
  | SetAddress
  | SetCurrentOption
  | SetPaymentMethod
  | ResetCart
  | ResetTimeslot
  | SetAddressManuallyAdded;

export interface ShoppingCartState {
  valid: boolean;
  rescheduleValid: boolean;
  products: ProductInCart[];
  totalPrice: number;
  discountCode?: DiscountCode;
  pickupRange?: Range;
  dropOffRange?: Range;
  options: Option[];
  currentOption?: Option;
  paymentMethod?: PaymentMethod;
  address?: ExtendedGoogleMapsPlace;
  addressManuallyAdded: boolean;
}
