import React from "react";
import { Provider } from "react-redux";

import createStore from "./src/store";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = createStore();
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LcD5-4mAAAAAHGG0o6Dby69Li_dwEQ4ukDUNuhr">
      <Provider store={store}>{element}</Provider>
    </GoogleReCaptchaProvider>
  );
};
