// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-add-address-tsx": () => import("./../../../src/pages/add-address.tsx" /* webpackChunkName: "component---src-pages-add-address-tsx" */),
  "component---src-pages-add-payment-tsx": () => import("./../../../src/pages/add-payment.tsx" /* webpackChunkName: "component---src-pages-add-payment-tsx" */),
  "component---src-pages-addresses-tsx": () => import("./../../../src/pages/addresses.tsx" /* webpackChunkName: "component---src-pages-addresses-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-change-address-tsx": () => import("./../../../src/pages/change-address.tsx" /* webpackChunkName: "component---src-pages-change-address-tsx" */),
  "component---src-pages-delivery-not-available-tsx": () => import("./../../../src/pages/delivery-not-available.tsx" /* webpackChunkName: "component---src-pages-delivery-not-available-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-order-confirmation-tsx": () => import("./../../../src/pages/order-confirmation.tsx" /* webpackChunkName: "component---src-pages-order-confirmation-tsx" */),
  "component---src-pages-order-reschedule-tsx": () => import("./../../../src/pages/order-reschedule.tsx" /* webpackChunkName: "component---src-pages-order-reschedule-tsx" */),
  "component---src-pages-order-tsx": () => import("./../../../src/pages/order.tsx" /* webpackChunkName: "component---src-pages-order-tsx" */),
  "component---src-pages-orders-tsx": () => import("./../../../src/pages/orders.tsx" /* webpackChunkName: "component---src-pages-orders-tsx" */),
  "component---src-pages-payment-methods-tsx": () => import("./../../../src/pages/payment-methods.tsx" /* webpackChunkName: "component---src-pages-payment-methods-tsx" */),
  "component---src-pages-payment-webview-tsx": () => import("./../../../src/pages/payment-webview.tsx" /* webpackChunkName: "component---src-pages-payment-webview-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-signin-from-cart-tsx": () => import("./../../../src/pages/signin-from-cart.tsx" /* webpackChunkName: "component---src-pages-signin-from-cart-tsx" */),
  "component---src-pages-signin-tsx": () => import("./../../../src/pages/signin.tsx" /* webpackChunkName: "component---src-pages-signin-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-validate-code-from-cart-tsx": () => import("./../../../src/pages/validate-code-from-cart.tsx" /* webpackChunkName: "component---src-pages-validate-code-from-cart-tsx" */),
  "component---src-pages-validate-code-tsx": () => import("./../../../src/pages/validate-code.tsx" /* webpackChunkName: "component---src-pages-validate-code-tsx" */),
  "component---src-templates-page-content-tsx": () => import("./../../../src/templates/page-content.tsx" /* webpackChunkName: "component---src-templates-page-content-tsx" */)
}

