import { PaymentMethod } from '../../types/payment-method';
import { ApiAddress } from '../../types/address';

export const SET_ADDRESS = 'SET_ADDRESS';
export const SET_ADDRESS_DETAILS = 'SET_ADDRESS_DETAILS';
export const SET_ADDRESSES = 'SET_ADDRESSES';
export const SET_USER = 'SET_USER';
export const SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS';
export const DELETE_PAYMENT_METHOD = 'DELETE_PAYMENT_METHOD';
export const SET_DEFAULT_PAYMENT_METHOD = 'SET_DEFAULT_PAYMENT_METHOD';
export const SET_DEFAULT_LANGUAGE = 'SET_DEFAULT_LANGUAGE';

interface SetUser {
  reference: string;
  type: typeof SET_USER;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

interface SetAddress {
  type: typeof SET_ADDRESS;
  placeId: string;
  addressType: string;
  description: string;
}

interface SetAddressDetails {
  type: typeof SET_ADDRESS_DETAILS;
  details: string;
}

interface DeletePaymentMethod {
  type: typeof DELETE_PAYMENT_METHOD;
  id: string;
}

interface SetDefaultPaymentMethod {
  type: typeof SET_DEFAULT_PAYMENT_METHOD;
  id: string;
}

export interface Address {
  placeId?: string;
  type?: string;
  description?: string;
  details?: string;
}

interface SetAddresses {
  type: typeof SET_ADDRESSES;
  addresses: ApiAddress[];
}

interface SetDefaultLanguage {
  type: typeof SET_DEFAULT_LANGUAGE;
  language: string;
}

export type UserActionTypes = SetAddress | SetAddressDetails | SetUser | SetPaymentMethods | DeletePaymentMethod | SetAddresses | SetDefaultPaymentMethod | SetDefaultLanguage;

interface SetPaymentMethods {
  type: typeof SET_PAYMENT_METHODS;
  paymentMethods: PaymentMethod[];
}

export interface UserState {
  language: string;
  reference?: string;
  address?: Address;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  paymentMethods: PaymentMethod[];
  addresses: ApiAddress[];
}
