import {
  SET_ADDRESSES,
  DELETE_PAYMENT_METHOD,
  SET_ADDRESS,
  SET_ADDRESS_DETAILS,
  SET_USER,
  SET_PAYMENT_METHODS,
  UserActionTypes,
  UserState,
  SET_DEFAULT_PAYMENT_METHOD,
  SET_DEFAULT_LANGUAGE,
} from "./types";

const initialState: UserState = {
  language: "fr",
  paymentMethods: [],
  addresses: [],
};

const userReducer = (
  state = initialState,
  action: UserActionTypes
): UserState => {
  switch (action.type) {
    case SET_USER: {
      return {
        ...state,
        firstName: action.firstName,
        lastName: action.lastName,
        email: action.email,
        phoneNumber: action.phoneNumber,
        reference: action.reference,
      };
    }
    case SET_ADDRESS: {
      return {
        ...state,
        address: {
          placeId: action.placeId,
          type: action.addressType,
          description: action.description,
          details: state.address ? state.address.details : "",
        },
      };
    }
    case SET_ADDRESS_DETAILS: {
      return {
        ...state,
        address: {
          ...state.address,
          details: action.details,
        },
      };
    }
    case SET_PAYMENT_METHODS: {
      return {
        ...state,
        paymentMethods: action.paymentMethods,
      };
    }
    case DELETE_PAYMENT_METHOD: {
      const paymentMethods = state.paymentMethods.filter(
        pm => pm.id != action.id
      );
      return {
        ...state,
        paymentMethods,
      };
    }
    case SET_DEFAULT_PAYMENT_METHOD: {
      const paymentMethods = state.paymentMethods.map(pm => ({
        ...pm,
        isDefault: action.id === pm.id,
      }));
      return {
        ...state,
        paymentMethods,
      };
    }
    case SET_ADDRESSES: {
      return {
        ...state,
        addresses: action.addresses,
      };
    }
    case SET_DEFAULT_LANGUAGE: {
      return {
        ...state,
        language: action.language,
      };
    }
    default:
      return state;
  }
};

export default userReducer;
