import { SET_CATEGORIES, SET_PRODUCTS, SET_LUXE_PRODUCTS, SET_LUXE_CATEGORIES ,ProductsActionTypes, ProductsState } from "./types";
import { CategoryMap } from "../../types/category";

const initialState: ProductsState = {
  allProducts: [],
  categories: {},
  luxeCategories: {},
  luxeProducts: []
};

const ProductsReducer = (
  state = initialState,
  action: ProductsActionTypes
): ProductsState => {
  switch (action.type) {
    case SET_PRODUCTS: {
      return {
        ...state,
        allProducts: action.products,
      };
    }
    case SET_LUXE_PRODUCTS: {
      return {
        ...state,
        luxeProducts: action.products,
      };
    }
    case SET_CATEGORIES: {
      return {
        ...state,
        categories: action.categories.reduce((categoryMap, category) => {
          categoryMap[category.id] = category;
          return categoryMap;
        }, {} as CategoryMap),
      };
    }
    case SET_LUXE_CATEGORIES: {
      return {
        ...state,
        luxeCategories: action.categories.reduce((categoryMap, luxeCategory) => {
          categoryMap[luxeCategory.id] = luxeCategory;
          return categoryMap;
        }, {} as CategoryMap),
      };
    }
    default:
      return state;
  }
};

export default ProductsReducer;
