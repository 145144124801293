import { AuthActionTypes, AuthState, SET_PHONE_NUMBER, SIGN_IN } from './types';

const initialState: AuthState = {
  phoneNumber: '',
  token: '',
};

const authReducer = (state = initialState, action: AuthActionTypes): AuthState => {
  switch (action.type) {
    case SET_PHONE_NUMBER: {
      return {
        ...state,
        phoneNumber: action.phoneNumber,
      };
    }
    case SIGN_IN: {
      return {
        ...state,
        token: action.token,
      };
    }
    default:
      return state;
  }
};

export default authReducer;
