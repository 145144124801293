import { Product } from '../../types/product';
import { Category, CategoryMap } from '../../types/category';

export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_LUXE_PRODUCTS = 'SET_LUXE_PRODUCTS';
export const SET_LUXE_CATEGORIES = 'SET_LUXE_CATEGORIES';

interface SetCategories{
  type: typeof SET_CATEGORIES;
  categories: Category[];
}

interface SetLuxeCategories{
  type: typeof SET_LUXE_CATEGORIES;
  categories: Category[];
}

interface SetProducts {
  type: typeof SET_PRODUCTS;
  products: Product[];
}

interface SetLuxeProducts {
  type: typeof SET_LUXE_PRODUCTS;
  products: Product[];
}

export type ProductsActionTypes =
  | SetCategories
  | SetProducts
  | SetLuxeCategories
  | SetLuxeProducts;

export interface ProductsState {
  allProducts: Product[];
  categories: CategoryMap;
  luxeCategories: CategoryMap;
  luxeProducts: Product[];
}
