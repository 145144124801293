import {
  ADD_PRODUCT,
  SET_PRODUCTS,
  ProductInCart,
  REMOVE_DISCOUNT_CODE,
  REMOVE_PRODUCT,
  RESET_CART,
  RESET_TIMESLOT,
  SET_CURRENT_OPTION,
  SET_DISCOUNT_CODE,
  SET_DROP_OFF_RANGE,
  SET_OPTIONS,
  SET_PAYMENT_METHOD,
  SET_PICKUP_RANGE,
  SET_ADDRESS,
  SET_ADDRESS_MANUALLY_ADDED,
  ShoppingCartActionTypes,
  ShoppingCartState,
} from "./types";
import { Product } from "../../types/product";
import { Discount } from "../../types/discount";

const initialState: ShoppingCartState = {
  products: [],
  options: [],
  currentOption: undefined,
  totalPrice: 0,
  valid: false,
  rescheduleValid: false,
  addressManuallyAdded: true,
};

const isValid = (state: ShoppingCartState): boolean =>
  state.address !== undefined &&
  state.pickupRange !== undefined &&
  state.dropOffRange !== undefined &&
  state.currentOption !== undefined &&
  state.paymentMethod !== undefined;

const isRescheduleValid = (state: ShoppingCartState): boolean => 
  state.pickupRange !== undefined &&
  state.dropOffRange !== undefined
    
const findProduct = (product: Product, products: ProductInCart[]): number => {
  return products.findIndex((f: ProductInCart) => f.product.id === product.id);
};

const calculateTotalPrice = (
  products: ProductInCart[],
  discount: Discount | undefined
): number => {
  let price = 0;

  products.forEach((product: ProductInCart) => {
    price += product.number * product.product.price;
  });

  if (discount && discount.type === "AMOUNT") {
    price -= discount.value;
  } else if (discount && discount.type === "PERCENT") {
    price -= (discount.value / 100) * price;
  }

  return price > 0 ? price : 0;
};

const addProduct = (product: Product, state: ShoppingCartState): ShoppingCartState => {
  const foundProduct = findProduct(product, state.products);

  if (foundProduct !== -1) {
    const products = [...state.products];
    products[foundProduct].number += 1;

    return {
      ...state,
      products,
      totalPrice: calculateTotalPrice(
        products,
        state.discountCode ? state.discountCode.discount : undefined
      ),
    };
  }

  const products = [
    ...state.products,
    { product: product, number: 1 },
  ];

  return {
    ...state,
    products,
    totalPrice: calculateTotalPrice(
      products,
      state.discountCode ? state.discountCode.discount : undefined
    ),
  };
};

const shoppingCartReducer = (
  state = initialState,
  action: ShoppingCartActionTypes
): ShoppingCartState => {
  switch (action.type) {
    case ADD_PRODUCT: {
      return addProduct(action.product, state);
    }
    case SET_PRODUCTS: {
      let newState: ShoppingCartState = { ...state, products: [] };
      for (const product of action.products) {
        newState = addProduct(product, newState);
      }
      return newState;
    }
    case REMOVE_PRODUCT: {
      const foundProduct = findProduct(action.product, state.products);

      if (foundProduct !== -1) {
        const products = [...state.products];

        if (products[foundProduct].number > 1) {
          products[foundProduct].number -= 1;
        } else {
          products.splice(foundProduct, 1);
        }

        return {
          ...state,
          products,
          totalPrice: calculateTotalPrice(
            products,
            state.discountCode ? state.discountCode.discount : undefined
          ),
        };
      }
      return state;
    }
    case SET_DISCOUNT_CODE: {
      return {
        ...state,
        discountCode: action.discountCode,
        totalPrice: calculateTotalPrice(
          state.products,
          action.discountCode.discount
        ),
      };
    }
    case REMOVE_DISCOUNT_CODE: {
      return {
        ...state,
        discountCode: undefined,
        totalPrice: calculateTotalPrice(state.products, undefined),
      };
    }
    case SET_PICKUP_RANGE: {
      // console.log('.... [SET_PICKUP_RANGE] action.pickupRange', action.pickupRange);
      const newState = {
        ...state,
        pickupRange: action.pickupRange,
      };

      return {
        ...newState,
        valid: isValid(newState),
        rescheduleValid: isRescheduleValid(newState),
      };
    }
    case SET_DROP_OFF_RANGE: {
      // console.log('.... [SET_DROP_OFF_RANGE]', action.dropOffRange)
      const newState = {
        ...state,
        dropOffRange: action.dropOffRange,
      };

      return {
        ...newState,
        valid: isValid(newState),
        rescheduleValid: isRescheduleValid(newState),
      };
    }
    case SET_OPTIONS: {
      return {
        ...state,
        options: action.options,
      };
    }
    case SET_ADDRESS: {
      const newState = {
        ...state,
        address: action.address,
      };

      return {
        ...newState,
        valid: isValid(newState),
      };
    }
    case SET_CURRENT_OPTION: {
      const newState = {
        ...state,
        currentOption: action.currentOption,
        pickupRange: undefined,
        dropOffRange: undefined,
      };

      return {
        ...newState,
        valid: isValid(newState),
      };
    }
    case SET_PAYMENT_METHOD: {
      const newState = {
        ...state,
        paymentMethod: action.paymentMethod,
      };

      return {
        ...newState,
        valid: isValid(newState),
      };
    }
    case RESET_TIMESLOT: {
      const newState = {
        ...state,
        pickupRange: undefined,
        dropOffRange: undefined,
      };

      return {
        ...newState,
        valid: isValid(newState),
      };
    }
    case RESET_CART: {
      const newState = {
        ...state,
        address:undefined,
        products: [],
        totalPrice: 0,
        discountCode: undefined,
        pickupRange: undefined,
        dropOffRange: undefined,
        addressManuallyAdded: true,
      };

      return {
        ...newState,
        valid: isValid(newState),
      };
    }
    case SET_ADDRESS_MANUALLY_ADDED: {
      return {
        ...state,
        addressManuallyAdded: action.value,
      };
    }
    default:
      return state;
  }
};

export default shoppingCartReducer;
