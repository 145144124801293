export const SET_PHONE_NUMBER = 'SET_PHONE_NUMBER';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';

interface SetPhoneNumber {
  type: typeof SET_PHONE_NUMBER;
  phoneNumber: string;
}

interface SignIn {
  type: typeof SIGN_IN;
  token: string;
}

interface SignOut {
  type: typeof SIGN_OUT;
}

export type AuthActionTypes = SetPhoneNumber | SignIn | SignOut;

export interface AuthState {
  phoneNumber: string;
  token: string;
}

export interface LoginResponse {
  success: boolean;
  error?: string;
}
