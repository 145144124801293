import { OrdersActionTypes, OrdersState, SET_ORDERS, UPDATE_ORDER } from "./types";
import { Order } from '../../types/order';

const initialState: OrdersState = {
  orders: [],
};

const ordersReducer = (
  state = initialState,
  action: OrdersActionTypes
): OrdersState => {
  switch (action.type) {
    case SET_ORDERS: {
      const orders: Order[] = [];
      for (const newOrder of action.orders) {
        const existingOrder = state.orders.find((o) => o.id === newOrder.id);
        if (!existingOrder) {
          orders.push(newOrder);
        } else {
          orders.push({ ...existingOrder, ...newOrder });
        }
      }
      return {
        ...state,
        orders: action.orders,
      };
    }
    case UPDATE_ORDER: {
      const index = state.orders.findIndex(order => order.id === action.order.id)

      if (index < 0) return state

      return {
        ...state,
        orders: [
          ...state.orders.slice(0, index),
          action.order,
          ...state.orders.slice(index + 1),
        ]
      }
    }
    default:
      return state;
  }
};

export default ordersReducer;
