import { Order } from "../../types/order";

export const SET_ORDERS = "SET_ORDERS";
export const UPDATE_ORDER = "UPDATE_ORDER"

interface SetOrders {
  type: typeof SET_ORDERS;
  orders: Order[];
}

interface UpdateOrder {
  type: typeof UPDATE_ORDER;
  order: Order;
}

export type OrdersActionTypes = SetOrders | UpdateOrder;

export interface OrdersState {
  orders: Order[];
  orderConfirmation?: Order;
}

export interface OrdersResponse {
  orders: Order[];
  page: number;
  perPage: number;
  total: number;
}

export interface Response {
  success: boolean;
  error?: string;
}
